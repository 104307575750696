import React from "react";
import { FaReact, FaDocker, FaNodeJs, FaPython } from "react-icons/fa";
import { TbBrandNextjs } from "react-icons/tb";
import { BiLogoPostgresql } from "react-icons/bi";
import { SiMongodb, SiKubernetes, SiTerraform } from "react-icons/si";

const SkillCard = ({ name, icon }) => {
  return (
    <div className="bg-gray-100 rounded-lg shadow-md p-6 flex flex-col items-center justify-center transition duration-300 hover:shadow-lg hover:-translate-y-1">
      <div className="text-4xl mb-4" role="img" aria-label={name}>
        {icon}
      </div>
      <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
    </div>
  );
};

const Skills = () => {
  const skills = [
    { name: "Next.js", icon: <TbBrandNextjs /> },
    { name: "React", icon: <FaReact /> },
    { name: "Node.js", icon: <FaNodeJs /> },
    { name: "Python", icon: <FaPython /> },
    { name: "Docker", icon: <FaDocker /> },
    { name: "PostgreSQL", icon: <BiLogoPostgresql /> },
    { name: "MongoDB", icon: <SiMongodb /> },
    { name: "Kubernetes", icon: <SiKubernetes /> },
    { name: "Terraform", icon: <SiTerraform /> },
  ];

  return (
    <section id="skills" className="py-20 bg-blue-50">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-12">My Skills</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {skills.map((skill, index) => (
            <SkillCard key={index} {...skill} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
